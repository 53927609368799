<template>
  <b-modal
    id="set-modal"
    ref="set-modal"
    :title="milestoneTitle"
    :ok-title="set.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <!-- Set start date and time -->
        <b-col cols="6">
          <b-form-group>
            <h5>{{ $t('SET_START_DATE_TIME') }}</h5>
            <flat-pickr
              v-model="set.start"
              type="text"
              class="form-control"
              :config="{minDate: formatSqlDate(content.start),
                        maxDate:formatSqlDate(content.end),
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col>
        <!-- Set end date and time -->
        <b-col cols="6">
          <b-form-group>
            <h5>{{ $t('SET_END_DATE_TIME') }}</h5>
            <flat-pickr
              v-model="set.end"
              type="text"
              class="form-control"
              :config="{minDate: formatSqlDate(content.start),
                        maxDate:formatSqlDate(content.end),
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i'}"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="d-flex align-items-center"
        >
          <h5>{{ $t('MESSAGE.DELETED_CONFIRMATION') }} ?</h5>
          <b-button
            variant="primary"
            class="ml-1"
            @click="handleDeleteSet()"
          >
            <span>{{ $t('BUTTON.YES') }}  </span>
            <feather-icon icon="CheckIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BModal,
  VBModal,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BForm,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BButton,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [mixinDate],
  model: {
    prop: 'editSet',
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    editSet: {
      type: Object,
      default: () => {},
    },
    getEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      set: {
        id: 0,
        contentId: 0,
        start: null,
        end: null,
      },
      nameState: null,
    }
  },
  computed: {
    milestoneTitle() {
      return `${this.$t('SET_MILESTONE')} ${this.set.description || ''}`
    },
  },
  watch: {
    editSet: {
      handler() {
        this.initializeSet()
      },
      immediate: true,
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('settime', [
      'fetchAllSettimes',
      'createSettime',
      'updateSettime',
      'deleteSettime']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    initializeSet() {
      if (this.editSet) {
        this.set = { ...this.editSet }
        this.set.start = moment(this.editSet.start, this.DASH_DATE_TIME_FORMAT).toDate()
        this.set.end = moment(this.editSet.end, this.DASH_DATE_TIME_FORMAT).toDate()
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.nameState = null
      this.set = {}
    },
    async handleDeleteSet() {
      try {
        await this.deleteSettime(this.set.id)
        await this.fetchAllSettimes(this.set.contentId)
        this.getEvents()
        this.resetModal()
        this.$bvModal.hide('set-modal')
        this.successMessage(this.$i18n.t('MESSAGE.SET_DELETED'))
      } catch (error) {
        if (error.response && error.response.status === 500) {
          this.errorMessage(this.$i18n.t('MESSAGE.INTERNAL_SERVER_ERROR'))
        } else {
          this.errorMessage(this.$i18n.t('MESSAGE.OPERATION_FAILED'))
        }
      }
    },
    convertToISO(dateString) {
      const date = new Date(`${dateString.replace(' ', 'T')}:00Z`)
      return date.toISOString()
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      this.set.contentId = this.content.id
      this.set.start = this.convertToISO(this.set.start)
      this.set.end = this.convertToISO(this.set.end)
      try {
        if (this.set.id) {
          await this.updateSettime(this.set)
          this.successMessage(this.$i18n.t('MESSAGE.SET_UPDATED'))
        } else {
          await this.createSettime(this.set)
          this.successMessage(this.$i18n.t('MESSAGE.SET_CREATED'))
        }
        await this.fetchAllSettimes(this.set.contentId)
        this.getEvents()
        this.resetModal()
        this.$bvModal.hide('set-modal')
      } catch (error) {
        this.errorMessage(this.$i18n.t('MESSAGE.OPERATION_FAILED'))
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
