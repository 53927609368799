<template>
  <b-modal
    id="set-time-modal"
    ref="set-time-modal"
    :title="$t('SETS_MILESTONES')"
    :ok-title="$t('UPDATE_ALL')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <!-- set date and time -->
        <b-col cols="12">
          <b-form-group>
            <h5>{{ $t('SET_TIME') }}</h5>
            <flat-pickr
              v-model="settime"
              type="text"
              class="form-control"
              :config="{noCalendar: true, enableTime: true,dateFormat: 'H:i'}"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BForm,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    getEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      settime: null,
      nameState: null,
    }
  },
  setup() {
    const {
      successMessage, errorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
    }
  },
  methods: {
    ...mapActions('settime', ['fetchAllSettimes', 'updateSettimeAll']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.nameState = null
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      const payload = { contentId: this.content.id, newStartTime: this.settime }
      if (this.content.id) {
        this.updateSettimeAll(payload).then(async () => {
          await this.fetchAllSettimes(this.content.id)
          this.getEvents()
          this.resetModal()
          this.$bvModal.hide('set-time-modal')
          this.successMessage(this.$i18n.t('MESSAGE.SET_UPDATED'))
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
